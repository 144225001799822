<template>
  <div class="team">
    <div class="team-member">
      <div class="team-member-flex">
        <span class="team-member__rank" :class="{ 'team-member__rank--me': userItem.its_me }">{{
          rank + 1
        }}</span>
        <avatar
          :status="userItem.is_online ? 'online' : 'offline'"
          class="team-member__avatar"
          :src="userItem.photo"
          :size="'small'"
        />
        <p class="team-member__name">{{ userItem.fio }}</p>
      </div>
    </div>

    <p v-if="!isTeam" class="team-points__point team-points__point--online">
      {{ user.group_info.is_online }}/{{ user.group_info.in_team }}
    </p>
    <p
      v-for="(item, key) in points"
      :key="key"
      class="team-points__point team-points__point--login_points"
    >
      {{ +item.toFixed(2) }}
    </p>
    <p v-if="!isTeam" class="team-points__point team-points__point--sum">
      {{ user.group_info.avg.sum }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Avatar from "@/components/event/room/Dashboard/components/common/Avatar";
import { auth, dashboard } from "@/store/modules/store.namespaces";

export default {
  components: {
    Avatar,
  },
  props: ["rank", "user", "isTeam"],
  data() {
    return {};
  },
  computed: {
    ...mapState(auth, {
      currentUser: "user",
    }),
    ...mapState(dashboard, ["userDashBoardMyTeamScore"]),
    userItem() {
      if (this.currentUser.customRole === "hd") {
        return this.user.leader;
      }
      return this.user;
    },
    points() {
      const column = this.userDashBoardMyTeamScore.columns;
      let userPoints;
      const { customRole } = this.currentUser;
      if (customRole === "hd") {
        userPoints = this.user.group_info.avg;
      } else {
        userPoints = this.user.points;
      }
      const res = {};
      Object.values(column).forEach(item => {
        if (item.name) {
          res[item.name] = userPoints[item.name];
        }
      });
      if (customRole != "hd") {
        res.sum = userPoints.sum;
      }
      return res;
    },
  },
};
</script>

<style lang="less" scoped>
.team {
  display: table-row;

  &-points {
    display: flex;
    flex: 1;
    justify-content: space-around;

    &__point {
      display: table-cell;
      width: 90px;
      padding-top: 10px;
      padding-bottom: 6px;
      padding-left: 8px;
      margin: 0;
      font-size: 13px;
      color: #020202;
      text-align: center;
      border-bottom: 1px solid var(--secondary-color);
    }
  }

  &-member {
    display: table-cell;
    padding-top: 10px;
    padding-bottom: 6px;
    padding-left: 8px;
    border-bottom: 1px solid var(--secondary-color);

    &-flex {
      display: flex;
      align-items: center;
    }

    &__rank {
      display: inline-block;
      flex-shrink: 0;
      width: 34px;
      height: 25px;
      margin-right: 5px;
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
      color: #fff;
      text-align: center;
      background-color: var(--base-color-lighter);
      border-radius: 12px;

      &--me {
        background-color: var(--base-accent-color);
      }
    }

    &__avatar {
      margin-right: 12px;
    }

    &__name {
      padding: 0;
      margin: 0;
      font-size: 13px;
      color: #242424;
    }
  }
}
</style>
