<template>
  <div class="rating">
    <div class="rating-icon icon-trophy"></div>

    <div class="rating-info">
      <p class="rating-info-p rating-info__title">{{ title }}:</p>
      <p class="rating-info-p rating-info__rating">
        {{ place }} {{ $t("TeamRating.place") }} {{ outOf }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["place", "outOf", "title"],
};
</script>

<style lang="less" scoped>
.rating {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 375px) {
    flex-direction: row;
  }

  &-info {
    margin-top: 11px;
    margin-left: 11px;
    color: #fff;

    &-p {
      padding: 0;
      margin: 0;
    }

    &__title {
      font-size: 14px;
    }

    &__rating {
      font-size: 21px;
      transition: font-size 0.3s ease-in-out;

      @media (max-width: 900px) {
        font-size: 14px;
      }
    }
  }

  &-icon {
    position: relative;
    width: 60px;
    height: 60px;
    background: url("../../../../../../assets/images/abbott/abbott-vic_leaf.png");
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: 0.6;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 24px;
      line-height: 60px;
      color: #fff;
      text-align: center;
      opacity: 0.23;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
