<template>
  <dashboard-table>
    <template #upperBlock>
      <div :class="$style.container">
        <participant-details :class="$style.detail" />
        <participant-points />
      </div>
    </template>
  </dashboard-table>
</template>

<script>
import { mapActions } from "vuex";
import ParticipantDetails from "@/components/event/room/Dashboard/components/participant/ParticipantDetails";
import ParticipantPoints from "@/components/event/room/Dashboard/components/participant/ParticipantPoints";
import { dashboard } from "@/store/modules/store.namespaces";
import { GET_PARTICIPANTS_ADMIN_INFO } from "@/store/modules/dashboard/action-types";
import DashboardTable from "./components/common/DashboardTable";

export default {
  components: {
    DashboardTable,
    ParticipantDetails,
    ParticipantPoints,
  },
  created() {
    this.getParticipantsAdminInfo();
  },
  methods: {
    ...mapActions(dashboard, {
      getParticipantsAdminInfo: GET_PARTICIPANTS_ADMIN_INFO,
    }),
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  @include mq-tablet {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 10px;
  }
}

.detail {
  margin-bottom: 10px;

  @include mq-tablet {
    margin-bottom: 0;
  }
}
</style>
