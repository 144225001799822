<template>
  <div
    v-if="logoImg || roomName !== 'def'"
    class="participant-dept"
    :class="participantDeptClasses"
    :style="logoImg"
  ></div>
</template>

<script>
import { mapState } from "vuex";
import cd from "@/assets/images/abbott/units/cd.png";
import { rooms } from "@/utils/abbott-rooms.js";
import { dashboard, room } from "@/store/modules/store.namespaces";
import { makeUrl } from "@/utils";

export default {
  props: {
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(dashboard, ["userDashboardInfo"]),
    ...mapState(room, {
      room: "roomNumber",
    }),
    ...mapState(room, ["roomInfo"]),
    logoImg() {
      const iconUrl = this.roomInfo.logo;
      const colorBg = this.userDashboardInfo.img_background_color;
      return this.roomName === "def" && iconUrl
        ? {
            backgroundImage: `url(${makeUrl(iconUrl)})`,
            backgroundColor: colorBg,
          }
        : "";
    },
    roomName() {
      const room = rooms.find(item => {
        return this.room === item.room;
      });
      return (room && room.name) || "def";
    },
    image() {
      return { source: cd, background: "rgb(240,141,39)" };
    },
    participantDeptClasses() {
      return [
        {
          "participant-dept--reset-margin-right": this.isFullWidth,
        },
        this.roomName && `participant-dept--unit-${this.roomName}`,
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.reset-margin-right {
  margin-right: 0;
}
.participant-dept {
  height: 84px;
  border-radius: 8px;
  border: 1px solid lightblue;
  text-align: center;
  overflow: hidden;
  margin-bottom: 9px;
  &--reset-margin-right {
    .reset-margin-right;
  }
  @media (max-width: 664px) {
    .reset-margin-right;
  }

  &--unit-def {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  /*&--unit-cnn {*/
  /*background: url("~@/assets/images/abbott/units/cnn.png"), rgb(234, 79, 53);*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: contain;*/
  /*}*/

  /*&--unit-cn {*/
  /*background: url("~@/assets/images/abbott/units/cn.png"), rgb(234, 79, 53);*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: contain;*/
  /*}*/

  /*&--unit-wmh {*/
  /*background: url("~@/assets/images/abbott/units/wmh.png"), rgb(122, 30, 128);*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: contain;*/
  /*}*/

  /*&--unit-cd {*/
  /*background: url("~@/assets/images/abbott/units/cd.png"), rgb(240, 141, 39);*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: contain;*/
  /*}*/

  /*&--unit-chc {*/
  /*background: url("~@/assets/images/abbott/units/chc.png"), rgb(30, 77, 113);*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: contain;*/
  /*}*/

  /*&--unit-gh {*/
  /*background: url("~@/assets/images/abbott/units/gh.png"), rgb(0, 150, 68);*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: contain;*/
  /*}*/

  /*&--unit-trade {*/
  /*background: url("~@/assets/images/abbott/units/trade.png"),*/
  /*rgb(15, 181, 190);*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: contain;*/
  /*}*/

  img {
    height: 100%;
    margin: 0 auto;
  }
}
</style>
