var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('flex-column-stack',{staticClass:"participant-points",class:{
    'participant-points--sidebar-visible': _vm.isSidebarVisible && _vm.needAdaptateChart,
  }},[_c('div',{staticClass:"participant-points__upper-half"},[_c('div',{staticClass:"participant-points__meta"},[_c('p',{staticClass:"participant-points__title"},[_vm._v(_vm._s(_vm.$t("common.myPoints")))]),_c('switch-pills',{attrs:{"firstString":_vm.$t('common.today'),"secondString":_vm.$t('common.allDay'),"firstId":1,"secondId":2},on:{"handleToggle":_vm.handleToggle}})],1),_c('div',{staticClass:"participant-points__info"},[(_vm.isTodayVisible)?_c('div',{staticClass:"participant-points__chart"},[_c('p',{staticClass:"participant-points__team-points"},[_c('span',{staticClass:"participant-points__team-points--span",style:({ fontSize: _vm.chartsFontSizeForToday })},[_vm._v(_vm._s(_vm.summaryToday))]),_c('br'),_vm._v(_vm._s(_vm.pointTodayInRussian)+" ")]),_c('pie-chart',{attrs:{"legend":"false","library":{
            cutoutPercentage: 65,
            elements: { arc: { borderWidth: 0 } },
            tooltips: _vm.tooltip,
          },"width":_vm.chartRect.width,"height":_vm.chartRect.height,"data":_vm.pieToday,"colors":_vm.pieTodayColors,"donut":"true"}})],1):_vm._e(),(!_vm.isTodayVisible)?_c('div',{staticClass:"participant-points__chart"},[_c('p',{staticClass:"participant-points__team-points"},[_c('span',{staticClass:"participant-points__team-points--span",style:({ fontSize: _vm.chartsFontSizeForAll })},[_vm._v(_vm._s(_vm.summaryAll))]),_c('br'),_vm._v(_vm._s(_vm.pointAllInRussian)+" ")]),_c('pie-chart',{attrs:{"legend":"false","library":{
            cutoutPercentage: 65,
            elements: { arc: { borderWidth: 0 } },
            tooltips: _vm.tooltip,
          },"width":_vm.chartRect.width,"height":_vm.chartRect.height,"data":_vm.pieAll,"colors":_vm.pieAllColors,"donut":"true"}})],1):_vm._e(),_c('div',{staticClass:"participant-points__victory"},[(_vm.isTodayVisible)?_c('team-rating',{attrs:{"place":_vm.placeToday,"outOf":_vm.outOf,"title":_vm.$t('common.participantRate')}}):_vm._e(),(!_vm.isTodayVisible)?_c('team-rating',{attrs:{"place":_vm.placeAll,"outOf":_vm.outOf,"title":_vm.$t('common.participantRate')}}):_vm._e()],1)])]),(_vm.isTodayVisible)?_c('participant-point-badges',{attrs:{"points":_vm.computedPointsToday}}):_vm._e(),(!_vm.isTodayVisible)?_c('participant-point-badges',{attrs:{"points":_vm.computedAllPoints}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }