<template>
  <div class="manager">
    <avatar
      class="manager-img"
      :src="manager.leader.photo"
      :size="'med'"
      :status="status"
    />
    <p class="manager-rank">
      {{ $t("Dashboards.placeBalls", [manager.place, deepPoints]) }}
    </p>
    <p class="manager-name">{{ manager.leader.fio }}</p>
  </div>
</template>

<script>
import Avatar from "@/components/event/room/Dashboard/components/common/Avatar";

export default {
  components: {
    Avatar,
  },
  props: ["manager", "groupCount"],
  computed: {
    status() {
      return this.manager.is_online === true ? "online" : "offline";
    },
    deepPoints() {
      return (
        (this.manager &&
          this.manager.group_info &&
          this.manager.group_info.avg &&
          this.manager.group_info.avg.sum) ||
        0
      );
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/styles/_vars");
.manager {
  flex-basis: 25%;
  // margin-bottom: 24px;
  padding: 3px 5px;

  &-img {
    margin-bottom: 6px;
  }

  &-rank,
  &-name {
    padding: 0;
    margin: 0;
    color: #383838;
  }

  &-rank {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 3px;
  }

  &-name {
    font-size: 14px;
    line-height: 0.93;
  }
}
</style>
