<template>
  <flex-column-stack
    class="participant-points"
    :class="{
      'participant-points--sidebar-visible': isSidebarVisible && needAdaptateChart,
    }"
  >
    <div class="participant-points__upper-half">
      <div class="participant-points__meta">
        <p class="participant-points__title">{{ $t("common.myPoints") }}</p>
        <switch-pills
          :firstString="$t('common.today')"
          :secondString="$t('common.allDay')"
          :firstId="1"
          :secondId="2"
          @handleToggle="handleToggle"
        />
      </div>

      <div class="participant-points__info">
        <div v-if="isTodayVisible" class="participant-points__chart">
          <p class="participant-points__team-points">
            <span
              class="participant-points__team-points--span"
              :style="{ fontSize: chartsFontSizeForToday }"
              >{{ summaryToday }}</span
            >
            <br />{{ pointTodayInRussian }}
          </p>
          <pie-chart
            legend="false"
            :library="{
              cutoutPercentage: 65,
              elements: { arc: { borderWidth: 0 } },
              tooltips: tooltip,
            }"
            :width="chartRect.width"
            :height="chartRect.height"
            :data="pieToday"
            :colors="pieTodayColors"
            donut="true"
          ></pie-chart>
        </div>

        <div v-if="!isTodayVisible" class="participant-points__chart">
          <p class="participant-points__team-points">
            <span
              class="participant-points__team-points--span"
              :style="{ fontSize: chartsFontSizeForAll }"
              >{{ summaryAll }}</span
            >
            <br />{{ pointAllInRussian }}
          </p>
          <pie-chart
            legend="false"
            :library="{
              cutoutPercentage: 65,
              elements: { arc: { borderWidth: 0 } },
              tooltips: tooltip,
            }"
            :width="chartRect.width"
            :height="chartRect.height"
            :data="pieAll"
            :colors="pieAllColors"
            donut="true"
          ></pie-chart>
        </div>

        <div class="participant-points__victory">
          <team-rating
            v-if="isTodayVisible"
            :place="placeToday"
            :outOf="outOf"
            :title="$t('common.participantRate')"
          />
          <team-rating
            v-if="!isTodayVisible"
            :place="placeAll"
            :outOf="outOf"
            :title="$t('common.participantRate')"
          />
        </div>
      </div>
    </div>

    <participant-point-badges v-if="isTodayVisible" :points="computedPointsToday" />
    <participant-point-badges v-if="!isTodayVisible" :points="computedAllPoints" />
  </flex-column-stack>
</template>

<script>
import { mapState } from "vuex";
import { dashboard, room } from "@/store/modules/store.namespaces";
import FlexColumnStack from "@/components/event/layouts/room/FlexColumnStack";
import SwitchPills from "@/components/event/room/Dashboard/components/common/SwitchPills";
import TeamRating from "@/components/event/room/Dashboard/components/common/TeamRating";
import ParticipantPointBadges from "@/components/event/room/Dashboard/components/participant/ParticipantPointBadges";
import useChartTooltip from "@/components/event/room/Dashboard/components/mixins/useChartTooltip";
import { DASHBOARD_COLORS } from "@/constants/room/room-const";

const DEFAULT_CHART_FONT_SIZE = 36;
const SMALL_CHART_FONT_SIZE = 26;

export default {
  name: "ParticipantPoints",
  components: {
    FlexColumnStack,
    SwitchPills,
    TeamRating,
    ParticipantPointBadges,
  },
  mixins: [useChartTooltip],
  data() {
    return {
      isTodayVisible: true,
      colorPie: DASHBOARD_COLORS,
      bodyWidth: null,
    };
  },
  computed: {
    ...mapState(room, ["sidebar"]),
    ...mapState(dashboard, ["userDashBoardMyTeamScore", "userDashBoardMyScore"]),
    ...mapState(room, {
      locale: "interfaceLanguage",
    }),
    chartsFontSizeForToday() {
      if (this.summaryToday) {
        const stringLength = `${this.summaryToday}`.length - 3;
        return `${this.defaultChartFontSize - (stringLength > 0 ? stringLength : 0) * 3}px`;
      }
    },
    chartsFontSizeForAll() {
      if (this.summaryAll) {
        const stringLength = `${this.summaryAll}`.length - 3;
        return `${this.defaultChartFontSize - (stringLength > 0 ? stringLength : 0) * 3}px`;
      }
    },
    defaultChartFontSize() {
      return this.needAdaptateChart && this.isSidebarVisible
        ? SMALL_CHART_FONT_SIZE
        : DEFAULT_CHART_FONT_SIZE;
    },
    orderColumn() {
      return this.userDashBoardMyTeamScore.columns;
    },
    summaryAll() {
      return this.userDashBoardMyScore.my_score.sum;
    },
    placeToday() {
      return this.userDashBoardMyScore.place_today;
    },
    placeAll() {
      return this.userDashBoardMyScore.place;
    },
    outOf() {
      return this.userDashBoardMyScore.out_of;
    },
    myScore() {
      return this.userDashBoardMyScore.my_score;
    },
    myScoreToday() {
      return this.userDashBoardMyScore.my_score_today;
    },
    summaryToday() {
      return this.userDashBoardMyScore.my_score_today.sum;
    },
    pointTodayInRussian() {
      return this.$tc("plurals.point", this.summaryToday);
    },
    pointAllInRussian() {
      return this.$tc("plurals.point", this.summaryAll);
    },
    pieTodayColors() {
      return this.sortPiePointsColors(this.myScoreToday);
    },
    pieAllColors() {
      return this.sortPiePointsColors(this.myScore);
    },
    computedPointsToday() {
      return this.orderNameColumn(this.myScoreToday);
    },
    computedAllPoints() {
      return this.orderNameColumn(this.myScore);
    },
    pieToday() {
      return this.orderPieColumn(this.myScoreToday);
    },
    pieAll() {
      return this.orderPieColumn(this.myScore);
    },
    chartRect() {
      return {
        width: "280px",
        height: "150px",
      };
    },
    needAdaptateChart() {
      return this.bodyWidth >= 1024 && this.bodyWidth <= 1280;
    },
    isSidebarVisible() {
      return this.sidebar && this.sidebar.expanded;
    },
    todayLabel() {
      const label = this.$t("common.today");
      return label.charAt(0).toUpperCase() + label.slice(1);
    },
    tooltipVisible() {
      if (this.isTodayVisible) {
        return this.summaryToday > 0;
      }
      return this.summaryAll > 0;
    },
    tooltipParentClass() {
      return ".participant-points__chart";
    },
  },
  mounted() {
    this.bodyWidth = document.body.getBoundingClientRect().width;
    window.addEventListener("resize", this.handleBodyResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleBodyResize);
  },
  methods: {
    handleToggle() {
      this.isTodayVisible = !this.isTodayVisible;
    },
    orderPieColumn(points) {
      const res = [];
      const column = this.orderColumn;

      if (points.sum === 0) {
        return [[this.todayLabel, 100]];
      }

      for (const i in column) {
        res.push([column[i].label, points[column[i].name]]);
      }

      return res;
    },
    orderNameColumn(points) {
      const res = [];
      const column = this.orderColumn;

      for (const i in column) {
        res.push({
          label: column[i].label,
          name: column[i].name,
          point: points[column[i].name],
        });
      }
      res.push({
        label: this.$t("common.total"),
        name: "sum",
        point: points.sum,
      });
      return res;
    },
    sortPiePointsColors(points) {
      const res = [];
      const sortList = this.orderColumn;
      const { colorPie } = this;
      if (!points.sum) {
        res.push(colorPie.def);
        return res;
      }
      for (const i in sortList) {
        if (sortList.hasOwnProperty(i) && colorPie[sortList[i].name]) {
          res.push(colorPie[sortList[i].name]);
        } else {
          res.push(colorPie.def);
        }
      }
      return res;
    },
    handleBodyResize() {
      const body = document.body.getBoundingClientRect();
      this.bodyWidth = body.width;
    },
  },
};
</script>

<style lang="less" scoped>
.participant-points {
  border-radius: 8px;
  background-color: #ffffff;
  border: solid 1px #c0efff;
  padding: 10px;

  @media (max-width: 664px) {
    width: 100%;
    margin: 0;
  }

  &__upper-half {
    position: relative;
    height: 224px;
    margin-right: -10px;
    margin-bottom: 10px;
    background: url("../../../../../../assets/images/mountains.png") no-repeat;
    background-position: right -135px bottom -20px;
  }

  &__meta {
    display: flex;
    margin-bottom: 25px;
    padding-right: 10px;
    justify-content: space-between;
  }

  &__title {
    padding: 0;
    font-size: 21px;
    font-weight: 300;
    color: #303030;
    margin: 0 25px 0 0;
    white-space: nowrap;
    transition: font-size 0.3s ease-in-out;

    @media (max-width: 936px) {
      font-size: 14px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__chart {
    position: absolute;
    bottom: 20px;
    left: -70px;
    z-index: 10;
  }

  &__team-points {
    font-size: 16px;
    font-weight: 300;
    color: #303030;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    text-align: center;
    word-wrap: break-word;
    z-index: -1;
    &--span {
      font-size: 36px;
    }
  }

  &__victory {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  &--sidebar-visible {
    @media (min-width: 1024px) {
      .participant-points__victory ::v-deep .rating-info__rating {
        font-size: 14px;
      }

      .participant-points__title {
        font-size: 16px;
      }

      .participant-points__team-points {
        font-size: 14px;
      }
    }

    @media (min-width: 1200px) {

      .participant-points__title {
        font-size: 18px;
      }
    }
  }
}
</style>
