<template>
  <div class="table-cell">
    <span
      v-if="isTeam && heading !== $t('common.online')"
      class="table-pills"
      :style="style"
    >
      {{ heading }}
    </span>

    <span
      v-else-if="!isTeam"
      class="table-pills"
      :style="style"
    >
      {{ heading }}
    </span>
  </div>
</template>

<script>
import { DASHBOARD_COLORS } from "@/constants/room/room-const";

export default {
  props: ["isTeam", "heading", "name"],
  computed: {
    style() {
      return {
        backgroundColor: DASHBOARD_COLORS[this.name] || DASHBOARD_COLORS.def,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.table-cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid var(--secondary-color);
}

.table-pills {
  display: inline-block;
  padding: 3px 10px;
  font-size: 13px;
  color: white;
  background-color: #c0c0c0;
  border-radius: 15.5px;
}
</style>
