<template>
  <div class="manager-points">
    <flex-row-wrap class="manager-points__info">
      <p class="manager-points__info-title">{{ $t("common.myPoints") }}</p>
      <switch-pills
        :firstString="$t('common.today')"
        :secondString="$t('common.allDay')"
        :firstId="1"
        :secondId="2"
        v-on:handleToggle="handleToggle"
      />
    </flex-row-wrap>

    <flex-row-wrap class="manager-points__data">
      <div v-if="isTodayVisible" class="manager-points__chart">
        <pie-chart
          legend="false"
          :library="{
            cutoutPercentage: 65,
            elements: { arc: { borderWidth: 0 } },
            tooltips: tooltip,
          }"
          :width="pieChartWidth"
          :height="pieChartHeight"
          :data="pieToday"
          :colors="pieTodayColors"
          donut="true"
        ></pie-chart>
        <p class="manager-points__team-points">
          <span
            class="manager-points__team-points--span"
            :style="{ fontSize: chartsFontSizeForToday }"
            >{{ summaryToday }}</span
          ><br />{{ pointTodayInRussian }}
        </p>
      </div>

      <div v-if="!isTodayVisible" class="manager-points__chart">
        <pie-chart
          legend="false"
          :library="{
            cutoutPercentage: 65,
            elements: { arc: { borderWidth: 0 } },
            tooltips: tooltip,
          }"
          :width="pieChartWidth"
          :height="pieChartHeight"
          :data="pieAll"
          :colors="pieAllColors"
          donut="true"
        ></pie-chart>
        <p class="manager-points__team-points">
          <span
            class="manager-points__team-points--span"
            :style="{ fontSize: chartsFontSizeForAll }"
            >{{ summaryAll }}</span
          ><br />{{ pointAllInRussian }}
        </p>
      </div>

      <manager-point-badges v-if="isTodayVisible" :points="computedPointsToday" />

      <manager-point-badges v-else :points="computedAllPoints" />
    </flex-row-wrap>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { dashboard, room } from "@/store/modules/store.namespaces";

import FlexRowWrap from "@/components/event/layouts/room/FlexRowWrap";
import SwitchPills from "@/components/event/room/Dashboard/components/common/SwitchPills";
import ManagerPointBadges from "@/components/event/room/Dashboard/components/manager/ManagerPointBadges";
import useChartTooltip from "@/components/event/room/Dashboard/components/mixins/useChartTooltip";
import { DASHBOARD_COLORS } from "@/constants/room/room-const";

export default {
  name: "ManagerPoints",
  components: {
    FlexRowWrap,
    SwitchPills,
    ManagerPointBadges,
  },
  mixins: [useChartTooltip],
  data() {
    return {
      isTodayVisible: true,
      colorPie: DASHBOARD_COLORS,
    };
  },
  computed: {
    ...mapState(dashboard, ["userDashBoardMyScore", "userDashBoardMyTeamScore"]),
    ...mapState(room, {
      locale: "interfaceLanguage",
    }),
    chartsFontSizeForToday() {
      const DEFAULT_CHART_FONT_SIZE = 36;
      if (this.summaryToday) {
        const stringLength = `${this.summaryToday}`.length - 3;
        return `${DEFAULT_CHART_FONT_SIZE - (stringLength > 0 ? stringLength : 0) * 3}px`;
      }
    },
    chartsFontSizeForAll() {
      const DEFAULT_CHART_FONT_SIZE = 36;
      if (this.summaryAll) {
        const stringLength = `${this.summaryAll}`.length - 3;
        return `${DEFAULT_CHART_FONT_SIZE - (stringLength > 0 ? stringLength : 0) * 3}px`;
      }
    },
    summaryToday() {
      return this.userDashBoardMyScore.my_score_today.sum;
    },
    summaryAll() {
      return this.userDashBoardMyScore.my_score.sum;
    },
    placeToday() {
      return this.userDashBoardMyScore.place_today;
    },
    placeAll() {
      return this.userDashBoardMyScore.place;
    },
    outOf() {
      return this.userDashBoardMyScore.out_of;
    },
    orderColumnMr() {
      return this.userDashBoardMyTeamScore.columns;
    },
    myScore() {
      return this.userDashBoardMyScore.my_score;
    },
    myScoreToday() {
      return this.userDashBoardMyScore.my_score_today;
    },
    pointTodayInRussian() {
      return this.$tc("plurals.point", this.summaryToday);
    },
    pointAllInRussian() {
      return this.$tc("plurals.point", this.summaryAll);
    },
    pieTodayColors() {
      return this.sortPiePointsColors(this.myScoreToday);
    },
    pieAllColors() {
      return this.sortPiePointsColors(this.myScore);
    },
    computedPointsToday() {
      return this.sortPoints(this.myScoreToday);
    },
    computedAllPoints() {
      return this.sortPoints(this.myScore);
    },
    pieToday() {
      return this.sortPiePoints(this.myScoreToday);
    },
    pieAll() {
      return this.sortPiePoints(this.myScore);
    },
    todayLabel() {
      const label = this.$t("common.today");
      return label.charAt(0).toUpperCase() + label.slice(1);
    },
    pieChartWidth() {
      return this.$mqMobile ? "140px" : "205px";
    },
    pieChartHeight() {
      return this.$mqMobile ? "130px" : "175px";
    },
    tooltipVisible() {
      if (this.isTodayVisible) {
        return this.summaryToday > 0;
      }
      return this.summaryAll > 0;
    },
    tooltipParentClass() {
      return ".manager-points__chart";
    },
  },
  methods: {
    handleToggle() {
      this.isTodayVisible = !this.isTodayVisible;
    },

    sortPoints(points) {
      const obj = [];
      const column = this.orderColumnMr;
      for (const i in column) {
        obj.push({
          label: column[i].label,
          name: column[i].name,
          point: points[column[i].name],
        });
      }

      return obj;
    },
    sortPiePoints(points) {
      const obj = [];
      const column = this.orderColumnMr;
      if (points.sum === 0) {
        return [[this.todayLabel, 100]];
      }

      for (const i in column) {
        obj.push([column[i].label, points[column[i].name]]);
      }
      return obj;
    },
    sortPiePointsColors(points) {
      const res = [];
      const sortList = this.orderColumnMr;
      const { colorPie } = this;
      if (!points.sum) {
        res.push(colorPie.def);
        return res;
      }
      for (const i in sortList) {
        if (colorPie[sortList[i].name]) {
          res.push(colorPie[sortList[i].name]);
        } else {
          res.push(colorPie.def);
        }
      }
      return res;
    },
  },
};
</script>

<style lang="less" scoped>
/* stylelint-disable declaration-no-important */
.manager-points {
  width: 100% !important;
  padding: 10px 13px 24px 10px;
  margin-bottom: 8px;
  border: solid 1px #c0efff;
  border-radius: 8px;

  &__data {
    flex-wrap: nowrap !important;
  }

  &__info {
    margin-bottom: 23px;

    &-title {
      padding: 0;
      margin: 0 10px 0 0;
      font-size: 21px;
      font-weight: 300;
      color: #303030;
    }
  }

  &__chart {
    position: relative;
  }

  &__team-points {
    position: absolute;
    top: 87px;
    left: 50%;
    width: 100px;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 300;
    color: #303030;
    text-align: center;
    word-wrap: break-word;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 760px) {
      top: 57px;
    }

    &--span {
      font-size: 36px;
    }
  }

  &__pills {
    width: 100%;
  }
}
</style>
