<template>
  <dashboard-table>
    <flex-row-wrap slot="upperBlock" class="manager-upper-block">
      <manager-info />
      <manager-top-managers v-if="businessUnit.length < 2" />
      <top-managers v-else />
    </flex-row-wrap>
  </dashboard-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FlexRowWrap from "@/components/event/layouts/room/FlexRowWrap";
import ManagerInfo from "@/components/event/room/Dashboard/components/manager/ManagerInfo";
import ManagerTopManagers from "@/components/event/room/Dashboard/components/manager/ManagerTopManagers";
import { dashboard } from "@/store/modules/store.namespaces";
import { GET_MANAGER_ADMIN_INFO } from "@/store/modules/dashboard/action-types";
import TopManagers from "@/components/event/room/Dashboard/components/admin/TopManagers";
import { BUSINESS_UNIT } from "@/store/modules/dashboard/getter-types";
import DashboardTable from "./components/common/DashboardTable";

export default {
  components: {
    TopManagers,
    FlexRowWrap,
    ManagerInfo,
    ManagerTopManagers,
    DashboardTable,
  },
  computed: {
    ...mapGetters(dashboard, {
      businessUnit: BUSINESS_UNIT,
    }),
  },
  created() {
    this.getManagerAdminInfo();
  },
  methods: {
    ...mapActions(dashboard, { getManagerAdminInfo: GET_MANAGER_ADMIN_INFO }),
  },
};
</script>

<style lang="less" scoped>
.manager-upper-block {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 14px;
  margin-bottom: 14px;
}
</style>
