<template>
  <div class="table">
    <p v-if="myTeam" class="table__title">{{ title }}</p>

    <!-- table__heading -->
    <div class="table-wrapper">
      <div class="table__content">
        <div class="table__heading">
          <div class="table__heading-info">
            <div v-if="myTeam" class="table__team">
              <span class="icon-trophy table__team-icon"></span>
              <span class="table__team-online">
                {{ $t("common.online") }}: {{ isOnline }}/{{ inTeam }}</span
              >
            </div>
          </div>

          <div v-if="!myTeam" class="table-cell">
            <span class="table-pills table-pills-color-online">
              {{ $t("common.online") }}
            </span>
          </div>
          <table-pills
            v-for="item in pillsData"
            :key="item.name"
            :isTeam="myTeam"
            :heading="item.label"
            :name="item.name"
          />
          <div class="table-cell">
            <span class="table-pills table-pills-color-total">
              {{ $t("common.total") }}
            </span>
          </div>
        </div>
        <team-member
          v-for="(user, index) in users"
          :key="user.user_id"
          :rank="index"
          :user="user"
          :isTeam="myTeam"
        />

        <div class="table__total">
          <p class="table__total-heading">{{ $t("TeamTable.atotal") }}</p>
          <p
            v-for="(item, key) in total"
            :key="key"
            class="table__total-points-p table__total-points-p--login_points"
          >
            {{ item }}
          </p>
        </div>

        <div class="table__total">
          <p class="table__total-heading">{{ $t("TeamTable.avg") }}</p>
          <p
            v-for="(item, key) in totalAverage"
            :key="key"
            class="table__total-points-p table__total-points-p--login_points"
          >
            {{ item }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TablePills from "@/components/event/room/Dashboard/components/common/TablePills";
import TeamMember from "@/components/event/room/Dashboard/components/common/TeamMember";
import { dashboard } from "@/store/modules/store.namespaces";

export default {
  components: {
    TablePills,
    TeamMember,
  },
  props: ["title", "myTeam", "users", "total", "totalAverage"],
  data() {
    return {
      ranks: ["1", "2", "3", "4", "5", "6", "7"],
      points: [
        { heading: this.$t("common.online"), point: "9/12" },
        { heading: this.$t("TeamTable.presence"), point: "99" },
        { heading: this.$t("TeamTable.polls"), point: "8" },
        { heading: this.$t("TeamTable.questions"), point: "17" },
        { heading: this.$t("TeamTable.testing"), point: "38" },
        { heading: this.$t("TeamTable.practice"), point: "48" },
        { heading: this.$t("TeamTable.photoGame"), point: "15" },
        { heading: this.$t("TeamTable.total"), point: "28" },
      ],
    };
  },
  computed: {
    ...mapState(dashboard, ["userDashBoardMyTeamScore"]),
    inTeam() {
      return this.userDashBoardMyTeamScore.in_team;
    },
    isOnline() {
      return this.userDashBoardMyTeamScore.is_online;
    },
    pillsData() {
      return this.userDashBoardMyTeamScore.columns;
    },
  },
};
</script>

<style lang="less" scoped>
.table-cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid var(--secondary-color);
}

.table-pills {
  display: inline-block;
  padding: 3px 10px;
  font-size: 13px;
  color: white;
  background-color: #ff00c8;
  border-radius: 15.5px;
}

.table {
  &-wrapper {
    width: 100%;
    overflow-x: auto;
  }

  flex-shrink: 0;
  width: 100%;
  margin-bottom: 8px;
  border: solid 1px rgba(7, 165, 220, 0.28);
  border-radius: 8px;

  &__title {
    padding: 9px 0 0 13px;
    margin: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    color: #303030;
  }
}

.table__heading {
  display: table-row;
  height: 41px;

  &-info {
    display: table-cell;
    vertical-align: middle;
    border-bottom: 1px solid var(--secondary-color);
  }
}

.table__content {
  display: table;
  width: 1004px;
  overflow-x: scroll;
}

.table__total {
  display: table-row;
  font-size: 13px;

  &:not(:last-child) {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--secondary-color);
  }

  &-heading {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 85px;
    margin: 0;
    color: #303030;
    border-bottom: 1px solid var(--secondary-color);
  }

  &-points {
    display: flex;
    flex: 1;
    justify-content: space-around;
    padding-left: 8px;
    color: #020202;

    &-p {
      display: table-cell;
      width: 90px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 8px;
      margin: 0;
      text-align: center;
      border-bottom: 1px solid var(--secondary-color);
    }
  }

  &:last-child {
    .table__total-heading,
    .table__total-points-p {
      border: none;
    }
  }
}

.table__team {
  display: flex;
  padding-left: 8px;
  color: #fff;

  &-icon {
    display: inline-block;
    width: 34px;
    height: 25px;
    margin-right: 5px;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    background-color: var(--base-color-lighter);
    border-radius: 12px;
  }

  &-online {
    display: inline-block;
    width: 94px;
    height: 23px;
    font-size: 13px;
    line-height: 23px;
    text-align: center;
    background-color: #7ed321;
    border-radius: 15px;
  }
}
</style>
