<template>
  <point-badges :points="points" class="manager-points__pills"></point-badges>
</template>

<script>
import PointBadges from "@/components/event/room/Dashboard/components/common/PointBadges";

export default {
  components: {
    PointBadges,
  },
  props: ["points"],
};
</script>

<style lang="less" scoped>
.manager-points__pills {
  width: 100%;

  ::v-deep {
    .point {
      display: flex;
      justify-content: space-between;
      height: 24px;
      padding-right: 21px;
      padding-left: 14px;
      font-size: 14px;
      line-height: 24px;

      /* stylelint-disable selector-max-specificity */
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
