/**
 * Размер на которую нужно увеличить ширину тултипа
 * @memberof useChartTooltip
 * @constant
 * @type {number}
 */
const WIDTH_SHIFT = 40;
/**
 * Доп смещение влево при выходе за границы вьюпорта
 * @memberof useChartTooltip
 * @constant
 * @type {number}
 */
const LEFT_SHIFT = 35;
/**
 * @mixin useChartTooltip
 * @vue-computed {object} tooltip - кофигурация для тултипа используемого в диаграммах
 * @vue-computed {boolean} visible - свойство отвечающая за видимость тултипа
 * @vue-computed {string} id - ид для html элемента тултипа
 */
export default {
  computed: {
    tooltip() {
      return {
        enabled: false,
        custom: this.showChartTooltip,
        callbacks: {
          afterBody: this.afterBody,
        },
      };
    },
    /**
     * Завязано на свойство в компоненте где будет использован миксин
     * @returns {boolean}
     */
    visible() {
      return this.tooltipVisible;
    },
    id() {
      return `chart-tooltip-${this.$options.name}`;
    },
  },
  methods: {
    /**
     * Формирует и показывает тултип на диаграмме
     * @param {object} tooltipModel - модель тултипа
     */
    showChartTooltip(tooltipModel) {
      let tEl = document.getElementById(this.id);
      if (!tEl) {
        tEl = document.createElement("div");
        tEl.setAttribute("id", this.id);
        tEl.classList.add("chart-tooltip");
        const tParent = document.querySelector(this.tooltipParentClass);
        tParent.appendChild(tEl);
      }

      if (tooltipModel.opacity === 0) {
        tEl.style.opacity = 0;
        return;
      }
      if (tooltipModel.opacity && this.visible) {
        if (tooltipModel.body) {
          const [text] = tooltipModel.body.map(({ lines }) => lines);
          tEl.innerHTML = `${text} ${tooltipModel.afterBody[0]}`;
        }
        tEl.style.left = `${tooltipModel.caretX}px`;
        tEl.style.top = `${Math.round(tooltipModel.caretY)}px`;
        tEl.style.width = `${tooltipModel.width + WIDTH_SHIFT}px`;

        /* корректируем положение после того как в доме вычислилась исходная */
        this.$nextTick(() => {
          const outBodyShift = tEl.getBoundingClientRect().left;
          const left = outBodyShift <= 10 ? tooltipModel.caretX + LEFT_SHIFT : tooltipModel.caretX;
          tEl.style.opacity = 1;
          tEl.style.left = `${left}px`;
        });
      }
    },
    /**
     * Callback для обработки и вставки правильного суффикса в конец текста
     * @param {number} index - индекс элемента на который навели курсор
     * @param {Array} datasets - набор данных
     * @returns {string}
     */
    afterBody([{ index }], { datasets }) {
      const [{ data }] = datasets;
      return this.$tc("plurals.point", data[index]);
    },
  },
};
