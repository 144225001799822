<template>
  <div class="active-row">
    <div class="active-sort">
      <div class="active-sort--title" @click="showColumns = !showColumns">
        {{ selectSortTitle }} <i class="icon icon-arrow-down"></i>
      </div>
      <div v-show="showColumns" class="active-sort--items">
        <div
          v-for="item in lineActiveColumns"
          :key="item.name"
          class="active-sort--item"
          @click="selectLineActive(item.name)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>

    <div class="active-table-wrapper">
      <div class="active-table">
        <div class="active-table--head">
          <div class="active-table--th">
            {{ $t("Dashboards.tableColumnDate") }}
          </div>
          <div class="active-table--th">
            {{ $t("Dashboards.tableColumnTime") }}
          </div>
          <div class="active-table--th">
            {{ $t("Dashboards.tableColumnActivityType") }}
          </div>
          <div class="active-table--th">
            {{ $t("Dashboards.tableColumnName") }}
          </div>
          <div class="active-table--th balls">
            <div>{{ $t("Dashboards.tableColumnBalls") }}</div>
            <div class="wrapper">
              <div>
                <span>Σ</span><span>{{ lineActive.sum }}</span>
              </div>
              <div>
                <span>Ср.&nbsp;</span><span>{{ lineActive.avg }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(item, key) in lineActiveActivity"
          :key="key"
          class="active-table--tr"
        >
          <div class="active-table--td">{{ item.date }}</div>
          <div class="active-table--td">{{ item.time }}</div>
          <div class="active-table--td">{{ item.type }}</div>
          <div class="active-table--td active-table--td-wrap">
            {{ item.name }}
          </div>
          <div class="active-table--td">
            {{ item.points }}/{{ item.max_points }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { dashboard } from "@/store/modules/store.namespaces";
import { GET_MY_LINE_ACTIVE } from "@/store/modules/dashboard/action-types";

export default {
  name: "News",
  props: ["listColumns"],
  data() {
    return {
      showColumns: false,
      checkActivity: "all",
    };
  },
  computed: {
    ...mapState(dashboard, ["userDashBoardUserLineActive"]),
    lineActive() {
      if (this.userDashBoardUserLineActive) {
        return this.userDashBoardUserLineActive;
      }
      return {};
    },
    lineActiveActivity() {
      if (this.userDashBoardUserLineActive) {
        return this.userDashBoardUserLineActive.activity;
      }
      return {};
    },
    lineActiveColumns() {
      if (this.userDashBoardUserLineActive) {
        const { columns } = this.userDashBoardUserLineActive;
        return [
          ...Object.values(columns).sort((a, b) => a.order - b.order),
          {
            label: this.$t("Dashboards.all"),
            name: "all",
            order: 0,
          },
        ];
      }
      return [];
    },
    selectSortTitle() {
      if (this.userDashBoardUserLineActive) {
        if (this.checkActivity === "all") {
          return this.$t("Dashboards.all");
        }
        return this.lineActiveColumns.find(l => l.name === this.checkActivity)?.label;
      }
      return {};
    },
  },
  created() {
    this.getMyLineActive();
  },
  methods: {
    ...mapActions(dashboard, { getMyLineActive: GET_MY_LINE_ACTIVE }),
    selectLineActive(lineActive) {
      this.showColumns = false;
      this.checkActivity = lineActive;
      this.getMyLineActive(lineActive === "all" ? "" : lineActive);
    },
  },
};
</script>

<style scoped lang="less">
.active-row {
  position: relative;
  padding-top: 8px;
  margin-bottom: 8px;
  border: 1px solid #b5e4f5;
  border-radius: 8px;
}

.active-sort {
  position: relative;
  display: inline-block;
  margin-left: 17px;
  font-size: 13px;
  user-select: none;

  &--title {
    width: 152px;
    height: 27px;
    padding: 0 48px 0 15px;
    overflow: hidden;
    line-height: 27px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background-color: var(--base-color);
    border-radius: 15px;

    .icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 35px;
      height: 27px;
      line-height: 27px;
      color: #fff;
      text-align: center;
      border-left: 1px solid #ffffff73;
    }
  }

  &--items {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    min-width: ~"calc(100% - 35px)";
    max-width: ~"calc(100% + 35px)";
    padding: 0 15px;
    cursor: pointer;
    background: #fff;
  }

  &--item {
    height: 27px;
    overflow: hidden;
    line-height: 27px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.active-table {
  &-wrapper {
    width: 100%;
    overflow-x: auto;
  }

  display: table;
  width: 100%;
  font-size: 13px;

  &--head {
    display: table-row;
    color: #0002099e;
  }

  /* stylelint-disable selector-max-specificity */
  &--th {
    display: table-cell;
    height: 36px;
    padding: 0 0 0 17px;
    vertical-align: middle;

    &.balls {
      padding-right: 17px;

      span {
        color: #020202;
      }

      .wrapper {
        display: table;

        > div {
          display: table-row;
        }

        > div > span {
          display: table-cell;
        }
      }
    }
  }

  &--tr {
    display: table-row;
  }

  &--td {
    display: table-cell;
    height: 45px;
    padding: 0 0 0 17px;
    vertical-align: middle;
    border-top: 1px solid #b5e4f5;
  }

  &--td-wrap {
    hyphens: auto;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}
</style>
