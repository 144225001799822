<template>
  <div class="dropdown">
    <button class="dropdown-btn" @click.prevent="toggleDropdown">
      {{ selectedValue }}
      <span class="icon-arrow-down dropdown-btn__icon"></span>
    </button>

    <div class="dropdown-content" :style="{ height: height + 'px' }">
      <ul ref="dropdownContent" class="dropdown-content__list">
        <li
          v-for="(value, i) in values"
          :key="i"
          class="dropdown-content__item"
          @click.prevent="changeSelectedValue(value)"
        >
          {{ value }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { room } from "@/store/modules/store.namespaces";

export default {
  props: {
    values: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedValue: this.values[0],
      isDropdownVisible: false,
      height: "",
    };
  },
  computed: {
    ...mapState(room, {
      room: "roomNumber",
    }),
  },
  mounted() {
    this.height = this.isDropdownVisible
      ? this.$refs.dropdownContent.clientHeight
      : 0;
    this.$emit("handleSelection", this.selectedValue, this.room);
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
      this.height = this.isDropdownVisible
        ? this.$refs.dropdownContent.clientHeight
        : 0;
    },
    changeSelectedValue(value) {
      this.isDropdownVisible = !this.isDropdownVisible;
      this.height = this.isDropdownVisible
        ? this.$refs.dropdownContent.clientHeight
        : 0;

      if (this.selectedValue !== value) {
        this.selectedValue = value;
        this.$emit("handleSelection", value, this.room);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dropdown {
  position: relative;
  z-index: 1;
  font-size: 13px;

  &-btn {
    position: relative;
    z-index: 1;
    width: auto;
    min-width: 150px;
    height: 31px;
    padding: 0;
    padding-right: 42px;
    padding-left: 18px;
    margin: 0;
    overflow: visible;
    font: inherit;
    line-height: normal;
    color: var(--base-button-active-color);
    cursor: pointer;
    background-color: var(--base-color);
    border: none;
    border-radius: 15px;
    outline: none;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;

    &::-moz-focus-inner {
      padding: 0;
      border: 0;
    }

    &__icon {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      width: 35px;
      height: 31px;
      font-size: 16px;
      line-height: 31px;
      border-left: 1px solid var(--base-color-lighter);
    }
  }

  &-content {
    position: absolute;
    width: 100%;
    margin-top: -23px;
    overflow: hidden;
    background-color: #ebfaff;
    border-radius: 4px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
    transition: height 0.3s;

    &__list {
      padding: 31px 8px 8px 18px;
      margin: 0;
      list-style-type: none;
    }

    &__item {
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 11px;
      }
    }
  }
}
</style>
