<template>
  <flex-column-stack class="manager-info">
    <person-details class="manager-info__avatar" :person="person" />
    <flex-row-wrap class="manager-info__badges">
      <participant-badges
        v-for="(badge, i) in badges"
        :key="i"
        :background="badge.reached ? 'green' : 'blue'"
        :tooltip="badge.title"
      />
    </flex-row-wrap>
    <manager-points />
    <average-team-points class="manager-info__average-points" />
  </flex-column-stack>
</template>

<script>
import { mapState } from "vuex";
import FlexColumnStack from "@/components/event/layouts/room/FlexColumnStack";
import PersonDetails from "@/components/event/room/Dashboard/components/common/PersonDetails";
import ManagerPoints from "@/components/event/room/Dashboard/components/manager/ManagerPoints";
import AverageTeamPoints from "@/components/event/room/Dashboard/components/common/AverageTeamPoints";
import ParticipantBadges from "@/components/event/room/Dashboard/components/participant/ParticipantBadges";
import FlexRowWrap from "@/components/event/layouts/room/FlexRowWrap";
import { dashboard } from "@/store/modules/store.namespaces";

export default {
  components: {
    FlexColumnStack,
    PersonDetails,
    ManagerPoints,
    AverageTeamPoints,
    ParticipantBadges,
    FlexRowWrap,
  },
  computed: {
    ...mapState(dashboard, {
      person: "userDashboardInfo",
    }),
    badges() {
      return (this.person && this.person.achieves) || [];
    },
  },
};
</script>

<style lang="less" scoped>
.manager-info {
  &__avatar {
    width: 100%;
    margin-bottom: 12px;
    justify-content: flex-start;
  }

  &__points {
    width: 100%;
  }

  &__badges {
    width: 355px;
    margin: -15px 0 15px auto;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__average-points {
    margin-right: 0;
  }
}
</style>
