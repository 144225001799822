<template>
  <flex-column-stack v-if="managers" class="top-managers">
    <flex-row-wrap class="top-managers__heading">
      <p class="top-managers__title">
        {{ $t("ManagerTopManager.topManagers") }} {{ business_unit }}
      </p>
    </flex-row-wrap>
    <flex-row-wrap class="top-managers__manager">
      <manager-top-manager
        v-for="(manager, i) in managers"
        :key="i"
        :manager="manager.leader"
        :leader="manager"
        :points_sum="manager.points_sum"
      />
    </flex-row-wrap>
  </flex-column-stack>
</template>

<script>
import { mapState } from "vuex";
import FlexColumnStack from "@/components/event/layouts/room/FlexColumnStack";
import FlexRowWrap from "@/components/event/layouts/room/FlexRowWrap";

import ManagerTopManager from "@/components/event/room/Dashboard/components/manager/ManagerTopManager";
import { auth, dashboard } from "@/store/modules/store.namespaces";

export default {
  components: {
    FlexColumnStack,
    FlexRowWrap,
    ManagerTopManager,
  },
  computed: {
    ...mapState(auth, ["user"]),
    ...mapState(dashboard, ["userDashboardInfo", "userDashBoardBUTeamsScore"]),
    business_unit() {
      let bUnit = "" + this.userDashboardInfo.business_unit;
      if (this.user.customRole === "rm") {
        bUnit = "" + bUnit.split(",")[0];
      }
      return bUnit !== "null" ? bUnit.toUpperCase() : "";
    },
    managers() {
      let managers = this.userDashBoardBUTeamsScore.groups;
      return managers && managers.length > 12
        ? managers.slice(0, 12)
        : managers;
    },
  },
};
</script>

<style lang="less" scoped>
.top-managers {
  justify-content: flex-start;
  padding: 10px;
  border: solid 1px #b5e4f5;
  border-radius: 8px;

  &__heading {
    margin-bottom: 18px;
  }

  &__title {
    width: 248px;
    padding: 0;
    margin: 0;
    font-size: 21px;
    font-weight: 300;
    color: #303030;
  }

  &__manager {
    justify-content: flex-start;
    height: 100%;
  }
}
</style>
