<template>
  <div class="switch">
    <span
      class="switch-item"
      :class="{ 'switch-item--active': currentId === firstId }"
      @click="setCurrentId(firstId)"
      >{{ firstString }}</span
    >
    <span
      class="switch-item"
      :class="{ 'switch-item--active': currentId === secondId }"
      @click="setCurrentId(secondId)"
      >{{ secondString }}</span
    >
  </div>
</template>

<script>
export default {
  props: ["firstString", "secondString", "firstId", "secondId"],
  data() {
    return {
      currentId: this.firstId,
    };
  },
  methods: {
    setCurrentId(id) {
      if (this.currentId !== id) {
        this.currentId = id;
        this.$emit("handleToggle");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.switch {
  display: flex;
  flex-shrink: 0;
  width: 187px;
  height: 23px;
  overflow: hidden;
  font-size: 16px;
  cursor: pointer;
  background-color: #8f1441;
  border: solid 1px #8f1441;
  border-radius: 11px;

  &-item {
    display: inline-block;
    flex: 1;
    text-align: center;
    background-color: #ffffff;
    transition: all 0.4s;

    &--active {
      color: #ffffff;
      background-color: #8f1441;
    }
  }
}
</style>
