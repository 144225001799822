<template>
  <span
    class="p-badge icon-trophy"
    :class="{
      'p-badge--green': background === 'green',
      'p-badge--blue': background === 'blue',
    }"
  >
    <span class="p-badge__tooltip">{{ tooltip }}</span>
  </span>
</template>

<script>
export default {
  props: ["background", "tooltip"],
};
</script>

<style lang="less" scoped>
.p-badge {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 41px;
  margin-bottom: 3px;
  cursor: pointer;
  background: lightblue;
  border-radius: 15px;

  &::before {
    display: inline-block;
    width: 100%;
    font-size: 19px;
    line-height: 41px;
    color: #ffffff;
    text-align: center;
  }

  &:not(:last-child) {
    margin-right: 7px;
  }

  &--green {
    background-image: linear-gradient(168deg, #b4ec51, #429321);
  }

  &--blue {
    background-color: #c7c7c7;
  }

  &__tooltip {
    position: absolute;
    bottom: -22px;
    left: 50%;
    height: 22px;
    padding: 0 8px;
    font-size: 12px;
    line-height: 22px;
    color: #303030;
    text-align: center;
    white-space: nowrap;
    visibility: hidden;
    background: #d2f3ff;
    border-radius: 10px;
    box-shadow: 0 8px 6px 0 rgba(0, 0, 0, 0.09);
    transition: all 0.05s;
    transform: translateX(-50%);

    &::after {
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -4px;
      content: "";
      border-right: 4px solid transparent;
      border-bottom: 4px solid #d2f3ff;
      border-left: 4px solid transparent;
    }
  }

  &:hover &__tooltip {
    z-index: 999;
    visibility: visible;
    opacity: 1;
  }
}
</style>
