<template>
  <div class="top-teams">
    <div class="top-teams__heading">
      <p class="top-teams__heading-title">
        {{ $t("Dashboards.topTeams") }}
      </p>
      <dropdown-managers
        :values="businessUnit"
        @handleSelection="handleSelection"
      />
    </div>

    <team-table :users="users" :total="total" :totalAverage="totalAverage" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import TeamTable from "@/components/event/room/Dashboard/components/common/TeamTable";
import { dashboard } from "@/store/modules/store.namespaces";
import { GET_GOD_TOP_TEAMS } from "@/store/modules/dashboard/action-types";
import DropdownManagers from "@/components/event/room/Dashboard/components/admin/DropdownManagers";
import { BUSINESS_UNIT } from "@/store/modules/dashboard/getter-types";

export default {
  components: {
    TeamTable,
    DropdownManagers,
  },
  data() {
    return {
      divisions: [],
    };
  },
  computed: {
    ...mapState(dashboard, {
      orderColumn: state => state.userDashBoardMyTeamScore.columns,
      users: state => state.godDashboardBUTopTeams.groups,
      godDashboardBUTopTeams: "godDashboardBUTopTeams",
    }),
    ...mapGetters(dashboard, {
      businessUnit: BUSINESS_UNIT,
    }),
    total() {
      let summary = this.godDashboardBUTopTeams.summary;
      if (summary) {
        return this.sortOrder(summary);
      }
      return {};
    },
    totalAverage() {
      let avg = this.godDashboardBUTopTeams.avg;
      if (avg) {
        return this.sortOrder(avg);
      }
      return {};
    },
  },
  methods: {
    ...mapActions(dashboard, {
      getGodTopTeams: GET_GOD_TOP_TEAMS,
    }),
    handleSelection(businessUnit) {
      this.getGodTopTeams(businessUnit);
    },
    sortOrder(points) {
      let res = {
        online: points.online + "/" + points.in_team,
      };

      for (let i in this.orderColumn) {
        res[this.orderColumn[i].name] = points[this.orderColumn[i].name];
      }
      res["sum"] = points.sum;
      return res;
    },
  },
};
</script>

<style lang="less" scoped>
.top-teams {
  flex-shrink: 0;
  margin-top: -31px;

  &__heading {
    display: flex;
    margin-bottom: 10px;

    &-title {
      padding: 0;
      margin: 0 12px 0 0;
      font-size: 18px;
      font-weight: 300;
      color: #303030;
    }
  }
}
</style>
