<template>
  <div class="dashboard-table" :class="{ loader: loading }">
    <loader v-if="loading"></loader>

    <slot v-if="!loading" name="upperBlock"></slot>

    <div v-if="!loading" class="nav-table-dashboard">
      <div
        class="nav-table-dashboard_item"
        :class="{ active: currentTable == 'teamEvent' }"
        @click="currentTable = 'teamEvent'"
      >
        {{ $t("common.myTeam") }}
      </div>
      <div
        class="nav-table-dashboard_item"
        :class="{ active: currentTable == 'news' }"
        @click="currentTable = 'news'"
      >
        {{ $t("common.tapeActivity") }}
      </div>
    </div>
    <team-table
      v-if="!loading"
      v-show="currentTable == 'teamEvent'"
      :users="users"
      :title="$t('common.myTeam')"
      :myTeam="true"
      :total="total"
      :totalAverage="totalAverage"
    />
    <news
      v-show="currentTable == 'news'"
      class="news"
      :listColumns="orderColumn"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import TeamTable from "@/components/event/room/Dashboard/components/common/TeamTable";
import News from "@/components/event/room/Dashboard/components/common/News";
import Loader from "@/components/common/elements/Loader";
import { dashboard } from "@/store/modules/store.namespaces";

export default {
  components: {
    TeamTable,
    News,
    Loader,
  },
  data() {
    return {
      currentTable: "teamEvent",
    };
  },
  computed: {
    ...mapState(dashboard, ["loading", "userDashBoardMyTeamScore"]),
    users() {
      if (this.userDashBoardMyTeamScore.users) {
        return this.userDashBoardMyTeamScore.users;
      }
      return [];
    },
    total() {
      if (this.userDashBoardMyTeamScore.users) {
        let summary = this.userDashBoardMyTeamScore.summary;
        return this.sortOrder(summary);
      }
      return {};
    },
    totalAverage() {
      if (this.userDashBoardMyTeamScore.users) {
        let avg = this.userDashBoardMyTeamScore.avg;
        return this.sortOrder(avg);
      }
      return {};
    },
    orderColumn() {
      return this.userDashBoardMyTeamScore.columns;
    },
  },
  methods: {
    sortOrder(points) {
      let res = {};

      for (let i in this.orderColumn) {
        res[this.orderColumn[i].name] = points[this.orderColumn[i].name];
      }
      res["sum"] = points.sum;

      return res;
    },
  },
};
</script>

<style lang="less" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* stylelint-disable declaration-no-important */
.dashboard-table {
  width: 100%;
  max-width: 1024px !important;
  padding: 14px 10px 17px 8px;
  margin: 0 auto;
}

.nav-table-dashboard {
  display: flex;
  flex-shrink: 0;
  margin-bottom: 19px;

  .passed {
    color: #7cde10;
  }

  .in-progress {
    color: #f28d02;
  }

  .negative {
    color: #f10830;
  }

  .nav-table-dashboard_item {
    padding: 3px 12px;
    margin-right: 13px;
    font-size: 21px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #8f1441;
    border-radius: 15px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: #fff;
      background-color: #8f1441;
    }
  }
}

@media (max-width: 600px) {
  .nav-table-dashboard {
    .nav-table-dashboard_item {
      margin-right: 10px;
      font-size: 16px;
      border-radius: 12px;
    }
  }
}
</style>
