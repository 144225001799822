<template>
  <flex-column-stack class="participant-details">
    <person-details :person="person" class="participant-details__person-details" />

    <flex-row-wrap class="participant-details__badges">
      <participant-badges
        v-for="(badge, i) in badges"
        :key="i"
        :background="badge.reached ? 'green' : 'blue'"
        :tooltip="badge.title"
      />
    </flex-row-wrap>

    <!-- <participant-dept :dept="department"/> -->
    <participant-dept :is-full-width="isFullWidth" />

    <average-team-points :is-full-width="isFullWidth" />
  </flex-column-stack>
</template>

<script>
import { mapState } from "vuex";
import FlexColumnStack from "@/components/event/layouts/room/FlexColumnStack";
import FlexRowWrap from "@/components/event/layouts/room/FlexRowWrap";

import AverageTeamPoints from "@/components/event/room/Dashboard/components/common/AverageTeamPoints";

import PersonDetails from "@/components/event/room/Dashboard/components/common/PersonDetails";
import ParticipantBadges from "@/components/event/room/Dashboard/components/participant/ParticipantBadges";
import ParticipantDept from "@/components/event/room/Dashboard/components/participant/ParticipantDept";
import { dashboard } from "@/store/modules/store.namespaces";

export default {
  components: {
    FlexColumnStack,
    FlexRowWrap,
    PersonDetails,
    ParticipantBadges,
    ParticipantDept,
    AverageTeamPoints,
  },
  computed: {
    ...mapState(["sidebar"]),
    ...mapState(dashboard, ["userDashboardInfo"]),
    isFullWidth() {
      return this.sidebar && this.sidebar.expanded && this.$mqTablet;
    },
    person() {
      return this.userDashboardInfo;
    },
    badges() {
      return (this.person && this.person.achieves) || [];
    },
  },
};
</script>

<style lang="less" scoped>
.participant-details {
  width: 100%;

  &__person-details {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 9px;
  }

  &__badges {
    margin: -15px 0 15px 126px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
</style>
