<template>
  <div>
    <div
      v-for="item in points"
      :key="item.name"
      class="point"
      :class="[`point--${badgesType}`]"
      :style="getStyle(item.name)"
    >
      <span class="point__title">{{ item.label }}</span>
      <span class="point__count">{{ item.point }}</span>
    </div>
  </div>
</template>

<script>
import { DASHBOARD_COLORS } from "@/constants/room/room-const";

export default {
  name: "PointBadges",
  props: {
    points: {
      type: Array,
    },
    badgesType: {
      type: String,
      default: "manager",
    },
  },
  methods: {
    getStyle(name) {
      return {
        backgroundColor: DASHBOARD_COLORS[name] || DASHBOARD_COLORS.def,
      };
    },
  },
};
</script>

<style scoped lang="less">
.point {
  padding: 0 5px;
  color: #ffffff;
  border-radius: 15px;

  &--participant {
    background-color: #ff00c8;
  }

  &--manager {
    background-color: #f44336;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
