<template>
  <div class="average-points" :class="averagePointsClasses">
    <div class="average-points__meta">
      <p class="average-points__title">{{ $t("AverageTeamPoints.avg") }}</p>
      <switch-pills
        :firstString="$t('common.today')"
        :secondString="$t('common.allDay')"
        :firstId="1"
        :secondId="2"
        @handleToggle="handleToggle"
      />
    </div>

    <div class="average-points__info">
      <div v-if="isTodayVisible" class="average-points__chart">
        <pie-chart
          legend="false"
          :library="{
            cutoutPercentage: 65,
            elements: { arc: { borderWidth: 0 } },
            tooltips: tooltip,
          }"
          :width="chartRect.width"
          :height="chartRect.height"
          :data="averageToday"
          :colors="averageTodayColors"
          :style="pieChartStyle"
          donut="true"
        />
        <p class="average-points__team-points">
          <span class="average-points__team-points--span">{{ summaryToday }}</span>
          <br />
          {{ pointTodayInRussian }}
        </p>
      </div>

      <div v-if="!isTodayVisible" class="average-points__chart">
        <pie-chart
          legend="false"
          :library="{
            cutoutPercentage: 65,
            elements: { arc: { borderWidth: 0 } },
            tooltips: tooltip,
          }"
          :width="chartRect.width"
          :height="chartRect.height"
          :data="averageAll"
          :colors="averageAllColors"
          :style="pieChartStyle"
          donut="true"
        />
        <p class="average-points__team-points">
          <span class="average-points__team-points--span">{{ summaryAll }}</span>
          <br />
          {{ pointAllInRussian }}
        </p>
      </div>

      <div class="average-points__victory">
        <team-rating
          v-if="isTodayVisible"
          :place="placeToday"
          :outOf="outOf"
          :title="$t('common.teamRate')"
        />
        <team-rating
          v-if="!isTodayVisible"
          :place="placeAll"
          :outOf="outOf"
          :title="$t('common.teamRate')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { dashboard, room } from "@/store/modules/store.namespaces";

import TeamRating from "@/components/event/room/Dashboard/components/common/TeamRating";
import SwitchPills from "@/components/event/room/Dashboard/components/common/SwitchPills";
import useChartTooltip from "@/components/event/room/Dashboard/components/mixins/useChartTooltip";
import { DASHBOARD_COLORS } from "@/constants/room/room-const";

export default {
  name: "AverageTeamPoints",
  components: {
    SwitchPills,
    TeamRating,
  },
  mixins: [useChartTooltip],
  props: {
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTodayVisible: true,
      colorPie: DASHBOARD_COLORS,
      bodyWidth: null,
    };
  },
  computed: {
    ...mapState(room, ["sidebar"]),
    ...mapState(dashboard, ["userDashBoardAvgTeamScore", "userDashBoardMyTeamScore"]),
    ...mapState(room, {
      locale: "interfaceLanguage",
    }),
    pieChartStyle() {
      return {
        position: "relative",
        "z-index": 1,
      };
    },
    chartRect() {
      return {
        width: "280px",
        height: "150px",
      };
    },
    summaryToday() {
      return this.userDashBoardAvgTeamScore.avg_today.sum;
    },
    summaryAll() {
      return this.userDashBoardAvgTeamScore.avg.sum;
    },
    placeToday() {
      return this.userDashBoardAvgTeamScore.place_today;
    },
    placeAll() {
      return this.userDashBoardAvgTeamScore.place;
    },
    outOf() {
      return this.userDashBoardAvgTeamScore.out_of;
    },
    avgToday() {
      return this.userDashBoardAvgTeamScore.avg_today;
    },
    avgAll() {
      return this.userDashBoardAvgTeamScore.avg;
    },
    orderColumn() {
      return this.userDashBoardMyTeamScore.columns;
    },
    pointTodayInRussian() {
      return this.$tc("plurals.point", this.summaryToday);
    },
    pointAllInRussian() {
      return this.$tc("plurals.point", this.summaryAll);
    },
    averageTodayColors() {
      return this.sortPiePointsColors(this.avgToday);
    },
    averageAllColors() {
      return this.sortPiePointsColors(this.avgAll);
    },
    averageToday() {
      return this.orderPieColumn(this.avgToday);
    },
    averageAll() {
      return this.orderPieColumn(this.avgAll);
    },
    averagePointsClasses() {
      return {
        "average-points--reset-margin-right": this.isFullWidth,
        "average-points--sidebar-visible": this.isSidebarVisible && this.needAdaptateChart,
      };
    },
    isSidebarVisible() {
      return this.sidebar && this.sidebar.expanded;
    },
    needAdaptateChart() {
      return this.bodyWidth >= 1024 && this.bodyWidth <= 1280;
    },
    tooltipVisible() {
      if (this.isTodayVisible) {
        return this.summaryToday > 0;
      }
      return this.summaryAll > 0;
    },
    tooltipParentClass() {
      return ".average-points__chart";
    },
  },

  mounted() {
    this.bodyWidth = document.body.getBoundingClientRect().width;
    window.addEventListener("resize", this.handleBodyResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleBodyResize);
  },
  methods: {
    handleToggle() {
      this.isTodayVisible = !this.isTodayVisible;
    },
    sortPiePointsColors(points) {
      const res = [];
      const sortList = this.orderColumn;
      const { colorPie } = this;
      if (!points.sum) {
        res.push(colorPie.def);
        return res;
      }
      for (const i in sortList) {
        if (colorPie[sortList[i].name]) {
          res.push(colorPie[sortList[i].name]);
        } else {
          res.push(colorPie.def);
        }
      }
      return res;
    },
    orderPieColumn(points) {
      const res = [];
      const column = this.orderColumn;

      if (points.sum === 0) {
        return [["Сегодня", 100]];
      }

      for (const i in column) {
        res.push([column[i].label, points[column[i].name]]);
      }

      return res;
    },
    handleBodyResize() {
      const body = document.body.getBoundingClientRect();
      this.bodyWidth = body.width;
    },
  },
};
</script>

<style lang="less" scoped>
.reset-margin-right {
  margin-right: 0;
}
.average-points {
  position: relative;
  height: 224px;
  padding-right: 7px;
  padding-top: 11px;
  padding-left: 17px;
  border-radius: 8px;
  border: solid 1px #c0efff;
  background: #ffffff url("~@/assets/images/abbott-mountain.png") no-repeat;
  background-position: right -135px bottom -20px;

  &--reset-margin-right {
    .reset-margin-right();
  }

  @media (max-width: 664px) {
    .reset-margin-right();
  }

  &__meta {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    padding: 0;
    margin: 0;
    font-size: 21px;
    font-weight: 300;
    color: #303030;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: font-size 0.3s ease-in-out;

    @media (max-width: 936px) {
      font-size: 14px;
    }
  }

  &__chart {
    position: absolute;
    bottom: 20px;
    left: -60px;
  }

  &__team-points {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    color: #303030;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;

    &--span {
      font-size: 25px;
    }
  }

  &__info {
    display: flex;
  }

  &__victory {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  &--sidebar-visible {
    @media (min-width: 1024px) {
      .average-points__victory ::v-deep .rating-info__rating {
        font-size: 14px;
      }

      .average-points__title {
        font-size: 14px;
      }

      .average-points__team-points {
        font-size: 14px;
      }
    }
  }
}
</style>
