<template>
  <div v-if="pending" class="dashboard-loader">
    <loader />
  </div>
  <participant v-else-if="customRole === 'mr' && !pending" />
  <admin v-else-if="customRole === 'hd' && !pending" />
  <manager v-else-if="customRole === 'rm' && !pending" />
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import VueChartkick from "vue-chartkick";
import Chart from "chart.js";
import { auth, room } from "@/store/modules/store.namespaces";
import "@/styles/components/_chart.less";
import Participant from "@/components/event/room/Dashboard/Participant";
import Admin from "@/components/event/room/Dashboard/Admin";
import Manager from "@/components/event/room/Dashboard/Manager";
import Loader from "@/components/common/elements/Loader";

if (!Object.keys(Vue.options.components).includes("pie-chart")) {
  Vue.use(VueChartkick, {
    adapter: Chart,
  });
}

export default {
  components: {
    Participant,
    Admin,
    Manager,
    Loader,
  },
  data() {
    return {
      pending: true,
    };
  },
  computed: {
    ...mapState(auth, {
      customRole: state => state.user.customRole,
    }),
    ...mapState(room, ["roomId"]),
  },
  watch: {
    roomId: {
      handler(value, oldValue) {
        if (value && !oldValue) {
          this.pending = false;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="less" scoped>
.dashboard-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
