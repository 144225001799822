<template>
  <div class="top-manager" :class="{ 'top-manager--self': manager.its_me }">
    <avatar
      class="top-manager__avatar"
      :size="'med'"
      :src="manager.photo"
      :status="status"
    />
    <p class="top-manager__place">
      {{ leader.place }} {{ $t("ManagerTopManager.place") }} /{{ deepPoints }}
      {{ $t("ManagerTopManager.points") }}
    </p>
    <p class="top-manager__name">{{ manager.fio }}</p>
  </div>
</template>

<script>
import Avatar from "@/components/event/room/Dashboard/components/common/Avatar";

export default {
  components: {
    Avatar,
  },
  props: ["manager", "points_sum", "leader"],
  computed: {
    status() {
      return this.manager.is_online === true ? "online" : "offline";
    },
    deepPoints() {
      return (
        (this.leader &&
          this.leader.group_info &&
          this.leader.group_info.avg &&
          this.leader.group_info.avg.sum) ||
        0
      );
    },
  },
};
</script>

<style lang="less" scoped>
.top-manager {
  width: 155px;
  height: 100px;
  padding: 10px 2px 0 10px;
  margin-bottom: 15px;
  color: #383838;
  border: solid 1px transparent;
  border-radius: 6px;

  &--self {
    background-color: #dff7ff;
    border: solid 1px var(--base-color-lighter);
  }

  &__avatar {
    margin-bottom: 5px;
  }

  &__place {
    padding: 0;
    margin: 0;
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: bold;
  }

  &__name {
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-size: 14px;
    line-height: 0.93;
    text-overflow: ellipsis;
  }
}
</style>
