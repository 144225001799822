export const rooms = [
  {
    room: `3322`,
    name: `cd`,
    permissions: ["commercial"]
  },
  {
    room: `9552`,
    name: `chc`,
    permissions: ["chc"]
  },
  {
    room: `4535`,
    name: `cn`,
    permissions: ["cn"]
  },
  {
    room: `3012`,
    name: `cnn`,
    permissions: ["cnn"]
  },
  {
    room: `4567`,
    name: `gh`,
    permissions: ["gh"]
  },
  {
    room: `1053`,
    name: `trade`,
    permissions: ["trade"]
  },
  {
    room: `2102`,
    name: `wmh`,
    permissions: ["wmh"]
  }
];
