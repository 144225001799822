var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"average-points",class:_vm.averagePointsClasses},[_c('div',{staticClass:"average-points__meta"},[_c('p',{staticClass:"average-points__title"},[_vm._v(_vm._s(_vm.$t("AverageTeamPoints.avg")))]),_c('switch-pills',{attrs:{"firstString":_vm.$t('common.today'),"secondString":_vm.$t('common.allDay'),"firstId":1,"secondId":2},on:{"handleToggle":_vm.handleToggle}})],1),_c('div',{staticClass:"average-points__info"},[(_vm.isTodayVisible)?_c('div',{staticClass:"average-points__chart"},[_c('pie-chart',{style:(_vm.pieChartStyle),attrs:{"legend":"false","library":{
          cutoutPercentage: 65,
          elements: { arc: { borderWidth: 0 } },
          tooltips: _vm.tooltip,
        },"width":_vm.chartRect.width,"height":_vm.chartRect.height,"data":_vm.averageToday,"colors":_vm.averageTodayColors,"donut":"true"}}),_c('p',{staticClass:"average-points__team-points"},[_c('span',{staticClass:"average-points__team-points--span"},[_vm._v(_vm._s(_vm.summaryToday))]),_c('br'),_vm._v(" "+_vm._s(_vm.pointTodayInRussian)+" ")])],1):_vm._e(),(!_vm.isTodayVisible)?_c('div',{staticClass:"average-points__chart"},[_c('pie-chart',{style:(_vm.pieChartStyle),attrs:{"legend":"false","library":{
          cutoutPercentage: 65,
          elements: { arc: { borderWidth: 0 } },
          tooltips: _vm.tooltip,
        },"width":_vm.chartRect.width,"height":_vm.chartRect.height,"data":_vm.averageAll,"colors":_vm.averageAllColors,"donut":"true"}}),_c('p',{staticClass:"average-points__team-points"},[_c('span',{staticClass:"average-points__team-points--span"},[_vm._v(_vm._s(_vm.summaryAll))]),_c('br'),_vm._v(" "+_vm._s(_vm.pointAllInRussian)+" ")])],1):_vm._e(),_c('div',{staticClass:"average-points__victory"},[(_vm.isTodayVisible)?_c('team-rating',{attrs:{"place":_vm.placeToday,"outOf":_vm.outOf,"title":_vm.$t('common.teamRate')}}):_vm._e(),(!_vm.isTodayVisible)?_c('team-rating',{attrs:{"place":_vm.placeAll,"outOf":_vm.outOf,"title":_vm.$t('common.teamRate')}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }