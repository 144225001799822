<template>
  <flex-row-wrap class="admin-details">
    <avatar
      class="admin-details__avatar"
      :src="person.photo"
      :status="'online'"
      :size="'big'"
    />
    <div class="admin-details__details">
      <p class="admin-details__name">{{ person.fio }}</p>
      <div class="admin-details__info">
        <p v-if="person.position" class="admin-details__title">
          <span class="icon-briefcase admin-details__icon"></span
          >{{ person.position }}
        </p>
        <p v-if="person.leader_name" class="admin-details__pos">
          <span class="icon-shield admin-details__icon"></span
          >{{ person.leader_name }}
        </p>
      </div>
    </div>
  </flex-row-wrap>
</template>

<script>
import FlexRowWrap from "@/components/event/layouts/room/FlexRowWrap";
import Avatar from "@/components/event/room/Dashboard/components/common/Avatar";

export default {
  components: {
    Avatar,
    FlexRowWrap,
  },
  props: ["person"],
};
</script>

<style lang="less" scoped>
.admin-details {
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 35%;

  &__details {
    padding-top: 5px;
  }

  &__avatar {
    flex-shrink: 0;
    margin-right: 13px;
  }

  &__name {
    padding: 0;
    margin: 0;
    margin-bottom: 14px;
    font-size: 16px;
    color: #353535;
  }

  &__info {
    font-size: 14px;
    font-weight: 600;
    color: #353535;

    & p {
      margin: 0;
      font-family: inherit;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  &__icon {
    display: inline-block;
    padding-right: 10px;
  }
}
</style>
