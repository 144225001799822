<template>
  <flex-column-stack class="admin" :class="{ loader: loading }">
    <loader v-if="loading"></loader>

    <flex-row-wrap v-if="!loading" class="admin__info">
      <person-details class="admin__details" :person="person" />
      <top-managers class="admin__managers" />
    </flex-row-wrap>

    <loader v-if="loadingTeam"></loader>
    <top-teams v-if="!loading" class="admin__teams" />
  </flex-column-stack>
</template>

<script>
import { mapActions, mapState } from "vuex";

import PersonDetails from "@/components/event/room/Dashboard/components/common/PersonDetails";
import TopManagers from "@/components/event/room/Dashboard/components/admin/TopManagers";
import TopTeams from "@/components/event/room/Dashboard/components/admin/TopTeams";
import FlexColumnStack from "@/components/event/layouts/room/FlexColumnStack";
import FlexRowWrap from "@/components/event/layouts/room/FlexRowWrap";
import Loader from "@/components/common/elements/Loader";
import { dashboard, room } from "@/store/modules/store.namespaces";
import {
  GET_ADMIN_INFO,
  GET_MY_TEAM_SCORE_RM, GET_USER_INFO,
} from "@/store/modules/dashboard/action-types";

export default {
  components: {
    FlexColumnStack,
    PersonDetails,
    FlexRowWrap,
    TopManagers,
    TopTeams,
    Loader,
  },
  data() {
    return {
      loading: true,
      loadingTeam: true,
    };
  },
  computed: {
    ...mapState(room, {
      room: "roomNumber",
    }),
    ...mapState(dashboard, {
      person: "userDashboardInfo",
    }),
  },
  async created() {
    await this.getUserInfo(this.room);
    this.loading = false;
    await this.getMyTeamScoreRm(this.room);
    this.loadingTeam = false;
  },
  methods: {
    ...mapActions(dashboard, {
      getUserInfo: GET_USER_INFO,
      getMyTeamScoreRm: GET_MY_TEAM_SCORE_RM,
    }),
  },
};
</script>

<style lang="less" scoped>
.loader {
  justify-content: center;
}

.admin {
  max-width: 1024px;
  padding: 14px 10px 17px 8px;

  &__details {
    width: 80%;
    margin-bottom: 5px;

    @media (min-width: 410px) {
      width: 60%;
    }

    @media (min-width: 768px) {
      width: 35%;
    }
  }

  &__info {
    flex-shrink: 0;
  }

  &__managers {
    min-width: 300px;

    ::v-deep .top-manager__heading {
      font-size: 16px;
    }

    @media (min-width: 410px) {
      min-width: 360px;

      ::v-deep .top-manager__heading {
        font-size: 21px;
      }
    }

    @media (min-width: 860px) {
      min-width: 450px;
    }
  }

  &__teams {
    margin-top: 0;

    @media (min-width: 860px) {
      margin-top: -31px;
    }
  }
}
</style>
