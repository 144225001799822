<template>
  <flex-column-stack class="top-manager" :class="{ loader: loading }">
    <loader v-if="loading"></loader>
    <div v-show="!loading" class="top-manager__head">
      <p class="top-manager__heading">{{ $t("Dashboards.topExecutives") }}</p>
      <dropdown-managers
        :values="businessUnit"
        @handleSelection="handleSelection"
      />
    </div>
    <flex-row-wrap v-show="!loading" class="top-manager__managers">
      <top-manager-info
        v-for="(manager, i) in managers.groups"
        :key="i"
        :manager="manager"
        :groupCount="managers.groups_count"
      />
    </flex-row-wrap>
  </flex-column-stack>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import FlexColumnStack from "@/components/event/layouts/room/FlexColumnStack";
import FlexRowWrap from "@/components/event/layouts/room/FlexRowWrap";
import TopManagerInfo from "@/components/event/room/Dashboard/components/admin/TopManagerInfo";
import Loader from "@/components/common/elements/Loader";
import { dashboard } from "@/store/modules/store.namespaces";
import { GET_GOD_TOP_MANAGERS } from "@/store/modules/dashboard/action-types";
import { BUSINESS_UNIT } from "@/store/modules/dashboard/getter-types";
import DropdownManagers from "@/components/event/room/Dashboard/components/admin/DropdownManagers";

export default {
  components: {
    FlexColumnStack,
    FlexRowWrap,
    TopManagerInfo,
    Loader,
    DropdownManagers,
  },
  data() {
    return {
      loading: true,
      divisions: [],
    };
  },
  computed: {
    ...mapState(dashboard, ["godDashBoardBUTopManagers", "userDashboardInfo"]),
    ...mapGetters(dashboard, {
      businessUnit: BUSINESS_UNIT,
    }),
    managers() {
      let managers = this.godDashBoardBUTopManagers;
      return managers.length > 12 ? managers.slice(0, 12) : managers;
    },
  },
  methods: {
    ...mapActions(dashboard, { getGodTopManagers: GET_GOD_TOP_MANAGERS }),
    async handleSelection(businessUnit) {
      await this.getGodTopManagers(businessUnit);
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.loader {
  justify-content: center;
}

.top-manager {
  flex: 1;
  min-height: 254px;
  padding: 10px 13px 0 15px;
  border: solid 1px #b5e4f5;
  border-radius: 8px;

  &__managers {
    justify-content: flex-start;
    height: 100%;
    padding: 0 0 10px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  &__heading {
    padding: 0;
    margin: 0;
    font-size: 21px;
    font-weight: 300;
    color: #212121;
  }
}
</style>
