var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manager-points"},[_c('flex-row-wrap',{staticClass:"manager-points__info"},[_c('p',{staticClass:"manager-points__info-title"},[_vm._v(_vm._s(_vm.$t("common.myPoints")))]),_c('switch-pills',{attrs:{"firstString":_vm.$t('common.today'),"secondString":_vm.$t('common.allDay'),"firstId":1,"secondId":2},on:{"handleToggle":_vm.handleToggle}})],1),_c('flex-row-wrap',{staticClass:"manager-points__data"},[(_vm.isTodayVisible)?_c('div',{staticClass:"manager-points__chart"},[_c('pie-chart',{attrs:{"legend":"false","library":{
          cutoutPercentage: 65,
          elements: { arc: { borderWidth: 0 } },
          tooltips: _vm.tooltip,
        },"width":_vm.pieChartWidth,"height":_vm.pieChartHeight,"data":_vm.pieToday,"colors":_vm.pieTodayColors,"donut":"true"}}),_c('p',{staticClass:"manager-points__team-points"},[_c('span',{staticClass:"manager-points__team-points--span",style:({ fontSize: _vm.chartsFontSizeForToday })},[_vm._v(_vm._s(_vm.summaryToday))]),_c('br'),_vm._v(_vm._s(_vm.pointTodayInRussian)+" ")])],1):_vm._e(),(!_vm.isTodayVisible)?_c('div',{staticClass:"manager-points__chart"},[_c('pie-chart',{attrs:{"legend":"false","library":{
          cutoutPercentage: 65,
          elements: { arc: { borderWidth: 0 } },
          tooltips: _vm.tooltip,
        },"width":_vm.pieChartWidth,"height":_vm.pieChartHeight,"data":_vm.pieAll,"colors":_vm.pieAllColors,"donut":"true"}}),_c('p',{staticClass:"manager-points__team-points"},[_c('span',{staticClass:"manager-points__team-points--span",style:({ fontSize: _vm.chartsFontSizeForAll })},[_vm._v(_vm._s(_vm.summaryAll))]),_c('br'),_vm._v(_vm._s(_vm.pointAllInRussian)+" ")])],1):_vm._e(),(_vm.isTodayVisible)?_c('manager-point-badges',{attrs:{"points":_vm.computedPointsToday}}):_c('manager-point-badges',{attrs:{"points":_vm.computedAllPoints}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }