<template>
  <point-badges
    :points="points"
    class="participant-points__lower-half"
    badges-type="participant"
  ></point-badges>
</template>

<script>
import PointBadges from "@/components/event/room/Dashboard/components/common/PointBadges";

export default {
  components: {
    PointBadges,
  },
  props: ["points"],
};
</script>

<style lang="less" scoped>
.participant-points__lower-half {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-gap: 5px;

  ::v-deep .point {
    display: inline-block;
    width: 100%;
    height: 90px;
    text-align: center;

    &__title {
      display: block;
      padding-top: 14px;
      margin-bottom: 6px;
      font-size: 16px;
      font-weight: 300;
    }

    &__count {
      font-size: 32px;
    }
  }
}
</style>
